var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-dashboard temporary-table"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headerProposalTable,"items":_vm.dataProposalTable,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","item-key":"name","no-data-text":"شما تاکنون هیچ پیشنهادی ارسال نکرده‌اید."},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"persistent":"","max-width":"350px","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-0 pa-0",attrs:{"text":""},on:{"click":function($event){return _vm.setIdForDeleteRequest(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error"}},[_vm._v(" mdi-delete ")])],1)]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-card',{staticClass:"d-flex flex-column justify-center align-center pb-10 send-jobOffer-freelancer"},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" آیا قصد حذف درخواست خود را دارید؟ ")])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" خیر ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.deleteItem(_vm.ProposalId)}}},[_vm._v(" بله ")])],1)],1)],1)],1)]}},{key:"item.content",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center service-table"},[_c('div',{staticClass:"d-flex flex-column align-start px-4"},[_c('div',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm._f("readMore")(item.content,15, '...'))+" ")])])])]}},{key:"item.project_title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center service-table"},[_c('div',{staticClass:"d-flex flex-column align-start px-4"},[_c('div',{staticClass:"m-0"},[_vm._v(" "+_vm._s(item['project_title'])+" ")])])])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center service-table"},[_c('div',{staticClass:"d-flex flex-column align-start px-4"},[_c('div',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm._f("changeDate")(item['updated_at']))+" ")])])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center service-table"},[_c('div',{staticClass:"d-flex flex-column align-start px-4"},[(item['deleted_at'] === null)?_c('div',{staticClass:"m-0 my-1 service-table-title"},[_vm._v(" "+_vm._s(_vm._f("proposalStatus")(item.status))+" ")]):_c('div',{staticClass:"m-0 service-table-title"},[_vm._v(" رد شده ")])])])]}}])}),(_vm.dataProposalTable.length >= 5)?_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"color":"primary"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }